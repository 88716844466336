<ng-container>
	<span mat-dialog-title>
		<h3>
			{{ 'setupProfile.setup' | translate }}
		</h3>
	</span>
	<div mat-dialog-content>
		<div class="stepper-container" tabindex="0">
			<centric-horizontal-stepper #horizontalStepper [linear]="true" [disableInactiveSteps]="true">
				<centric-step [stepControl]="generalInformationForm">
					<ng-template centricStepLabel>
						<span class="line-clamp-{{ maxLines }}"> {{ 'generalInformation.header' | translate }} </span>
					</ng-template>
					<frontend-general-information
						#generalInformation
						(selectedSupplierEvent)="onCurrentSupplierId($event)"></frontend-general-information>
					<centric-button
						centricStepperNext
						ariaAttributes
						[disabled]="!generalInformationForm.valid"
						>{{ 'generalInformation.next' | translate }}</centric-button
					>
				</centric-step>

				<centric-step [stepControl]="contactInformation.contactInformationForm">
					<ng-template centricStepLabel>
						<span class="line-clamp-{{ maxLines }}">{{ 'contactInformation.header' | translate }} </span>
					</ng-template>
					<frontend-contact-information #contactInformation></frontend-contact-information>
					<section class="contact-infomation-buttons">
						<centric-button centricStepperPrevious class="action-button" type="button-theme">
							{{ 'general.back' | translate }}
						</centric-button>
						<centric-button
							centricStepperNext
							ariaAttributes
							class="action-button"
							[disabled]="!contactInformationForm.valid"
							>{{ 'generalInformation.next' | translate }}</centric-button
						>
					</section>
				</centric-step>

				<centric-step [stepControl]="workingHoursEdit.workingHoursForm">
					<ng-template centricStepLabel>
						<span class="line-clamp-{{ maxLines }}">{{ 'workingHours.header' | translate }} </span>
					</ng-template>
					<frontend-working-hours-edit #workingHoursEdit></frontend-working-hours-edit>
					<centric-button centricStepperPrevious class="action-button" type="button-theme">
							{{ 'general.back' | translate }}
						</centric-button>
				</centric-step>
			</centric-horizontal-stepper>
		</div>
	</div>
	<div mat-dialog-actions>
		<centric-button type="button-theme" [id]="data.acceptButtonText" (click)="logout()">{{
			'general.logout' | translate
		}}</centric-button>
		<centric-button
			ariaAttributes
			[id]="data.acceptButtonText"
			[type]="data.acceptButtonType"
			[disabled]="shouldDisableFinishButton()"
			(click)="saveSupplierSetupProfile()">
			{{ 'setupProfile.finish' | translate }}
		</centric-button>
	</div>
</ng-container>
