import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SupplierModule } from './supplier/supplier.module';
import { environment } from '../environment/environment';
import { SupplierRegisterComponent } from './supplier-register/supplier-register.component';
import { DashboardComponent } from './_pages/dashboard/dashboard.component';
import { CommonL4LModule, DateAdapterModule, JwtInterceptor } from '@frontend/common';
import { SetupProfileComponent } from './_components/setup-profile/setup-profile.component';
import {
	CentricPanelModule,
	WindmillDatePickerModule,
	CentricRadioModule,
	CentricStepperModule,
	CentricTextarea2Module,
	CentricInputTimepicker2Module,
	WindmillComboButtonModule
} from '@windmill/ng-windmill';
import { TestComponent } from './_components/test/test.component';
import { SupplierEditComponent } from './_components/supplier-edit/supplier-edit.component';
import { OffersComponent } from './_pages/offers/offers.component';
import { CreateOfferComponent } from './_components/create-offer/create-offer.component';
import { DatePipe } from '@angular/common';
import { FrequencyOfUseComponent } from './_components/frequency-of-use/frequency-of-use.component';
import { AgeRestrictionComponent } from './_components/age-restriction/age-restriction.component';
import { PriceRangeComponent } from './_components/price-range/price-range.component';
import { RegistrationSuccessfulComponent } from './_components/registration-successful/registration-successful.component';
import { CommonUiModule, WindmillModule, HttpLoaderFactory } from '@frontend/common-ui';
import { TransactionsPanelComponent } from './_pages/dashboard/transactions-panel/transactions-panel.component';
import { UsedOffersPanelComponent } from './_pages/dashboard/used-offers-panel/used-offers-panel.component';
import { OffersChartPanelComponent } from './_pages/dashboard/offers-chart-panel/offers-chart-panel.component';
import { NgChartsModule } from 'ng2-charts';

@NgModule({
	declarations: [
		AppComponent,
		SupplierRegisterComponent,
		DashboardComponent,
		SetupProfileComponent,
		TestComponent,
		SupplierEditComponent,
		OffersComponent,
		CreateOfferComponent,
		FrequencyOfUseComponent,
		AgeRestrictionComponent,
		PriceRangeComponent,
		RegistrationSuccessfulComponent,
		TransactionsPanelComponent,
		UsedOffersPanelComponent,
		OffersChartPanelComponent
	],
	imports: [
		CommonUiModule.forRoot(environment),
		CommonL4LModule,
		WindmillModule,
		SupplierModule,
		CentricStepperModule,
		WindmillDatePickerModule,
		WindmillComboButtonModule,
		CentricTextarea2Module,
		CentricRadioModule,
		CentricInputTimepicker2Module,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		DateAdapterModule,
		RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
		CentricPanelModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		NgChartsModule
	],
	providers: [
		[DatePipe],
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
