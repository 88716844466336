export enum RestrictionFormFields {
    frequencyOfUse='frequencyOfUse',
	frequencyOfUseValue = 'frequencyOfUseValue',
	timeSlots = 'timeSlots',
	timeFrom = 'timeFrom',
	timeTo = 'timeTo',
	priceRange = 'priceRange',
	minPrice = 'minPrice',
	maxPrice = 'maxPrice',
    ageRestriction='ageRestriction',
	ageRestrictionValue = 'ageRestrictionValue',
	ageRestrictionOtherValue = 'ageRestrictionOtherValue'
}
