import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, RejectSupplierDto, SupplierViewDto } from '@frontend/common';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SupplierService {
	constructor(@Inject('env') private environment: Environment, private httpClient: HttpClient) {}

	public getSupplierRejectionInformation(supplierId: string): Observable<RejectSupplierDto> {
		return this.httpClient.get<RejectSupplierDto>(`${this.environment.apiPath}/supplier/rejection/${supplierId}`);
	}

	public getTestRequest(): Observable<string> {
		//TODO: delete this when a corresponding request will appear
		return this.httpClient.get<string>(`${this.environment.apiPath}/supplier/test`);
	}

	public getSupplierById(supplierId: string): Observable<SupplierViewDto> {
		return this.httpClient.get<SupplierViewDto>(`${this.environment.apiPath}/supplier/${supplierId}`);
	}

	public resetSupplierHasStatusUpdate(supplierId: string, value: boolean): Observable<void> {
		const httpParams = new HttpParams().set('hasStatusUpdate', value.toString());
		return this.httpClient.put<void>(
			`${this.environment.apiPath}/supplier/change-has-status-update/${supplierId}`,
			null,
			{ params: httpParams }
		);
	}

	public getQRCodeImage(): Observable<Blob> {
		return this.httpClient.get(`${this.environment.apiPath}/supplier/qr-code/`, {
			responseType: 'blob'
		});
	}
}
