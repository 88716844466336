import { GenericStatusEnum, OfferHolder } from "@frontend/common";

export class FilterOfferRequestDto {
    public status: GenericStatusEnum;
    public targetType: OfferHolder;
    public offerTypeId: number;
    public grantId: string;

    constructor(status: GenericStatusEnum, targetType: OfferHolder, offerTypeId: number, grantId: string) {
        this.status = status;
        this.targetType = targetType;
        this.offerTypeId = offerTypeId;
        this.grantId = grantId;
    }
}