<centric-panel [shadow]="true">
    <ng-container panel-header>
		<div class="left-container">
			<span class="line-clamp-1">{{ 'dashboard.transactions.title' | translate }}</span>
		</div>
	</ng-container>

    <ng-container panel-content>
    </ng-container>
    
</centric-panel>


