import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, GenericStatusEnum, GrantDto, OfferDto, OfferTableDto } from '@frontend/common';
import { Observable } from 'rxjs';
import { OfferType } from '../../models/offer-type.model';
import { DeleteOffersDto } from "../../models/delete-offers-dto.model";
import { ReactivateOfferDto } from "../../models/reactivate-offer-dto.model";
import { FilterOfferRequestDto } from '../../models/filter-offer-request-dto.model';
import { OfferRejectionReasonDto } from "../../models/offer-rejection-reason-dto.model";
import { OfferStatusCountsDto } from '../../models/offer-status-counts-dto.model';
import { TimeIntervalPeriod } from '../../enums/time-interval-period.enum';

@Injectable({
	providedIn: 'root'
})
export class OfferService {


	private static httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

	public shouldOpenOfferPopup = false;
	public offerStatusFilter: GenericStatusEnum | null;

	constructor(@Inject('env') private environment: Environment, private httpClient: HttpClient) { }

	public createOffer(offerDto: OfferDto): Observable<OfferDto> {
		return this.httpClient.post<OfferDto>(`${this.environment.apiPath}/offer`, offerDto, { headers: OfferService.httpHeaders });
	}

	public getFilteredOffers(filterOfferRequestDto: FilterOfferRequestDto, page: number, size: number): Observable<OfferTableDto[] | []> {
		let httpParams = new HttpParams()
			.set('status', filterOfferRequestDto.status || '')
			.set('targetType', filterOfferRequestDto.targetType || '')
			.set('grantId', filterOfferRequestDto.grantId || '')
			.set('pageIndex', page.toString())
			.set('pageSize', size.toString());

		const offerTypeId = filterOfferRequestDto.offerTypeId;

		if (typeof offerTypeId === 'number') {
			httpParams = httpParams.set('offerTypeId', offerTypeId.toString());
		}

		return this.httpClient.get<OfferTableDto[]>(`${this.environment.apiPath}/offer/filter`, { params: httpParams });
	}

	public getOfferTypes(): Observable<OfferType[] | null> {
		return this.httpClient.get<OfferType[]>(`${this.environment.apiPath}/offer/types`);
	}

	public getGrants(): Observable<GrantDto[] | null> {
		return this.httpClient.get<GrantDto[]>(`${this.environment.apiPath}/grant`);
	}

	public countOffers(): Observable<number> {
		return this.httpClient.get<number>(
			`${this.environment.apiPath}/offer/count`,
		);
	}

	public countFilteredOffers(filterOfferRequestDto: FilterOfferRequestDto): Observable<number> {
		let httpParams = new HttpParams()
			.set('status', filterOfferRequestDto.status || '')
			.set('targetType', filterOfferRequestDto.targetType || '')
			.set('grantId', filterOfferRequestDto.grantId || '');

		const offerTypeId = filterOfferRequestDto.offerTypeId;

		if (typeof offerTypeId === 'number') {
			httpParams = httpParams.set('offerTypeId', offerTypeId.toString());
		}

		return this.httpClient.get<number>(
			`${this.environment.apiPath}/offer/filter/count`,
			{ params: httpParams }
		);
	}

	public getOffers(page: number, size: number): Observable<OfferTableDto[]> {

		const httpParams = new HttpParams()
			.set('page', page)
			.set('size', size);

		return this.httpClient.get<OfferTableDto[]>(
			`${this.environment.apiPath}/offer`,
			{ params: httpParams }
		);
	}

	public deleteOffers(deleteOffersDto: DeleteOffersDto): Observable<void> {
		return this.httpClient.delete<void>(
			`${this.environment.apiPath}/offer/delete`,
			{ body: deleteOffersDto }
		);
	}

	public getFullOffer(offerId: string): Observable<OfferDto> {
		return this.httpClient.get<OfferDto>(
			`${this.environment.apiPath}/offer/full/${offerId}`
		);
	}

	public reactivateOffer(reactivateOfferDto: ReactivateOfferDto): Observable<void> {
		return this.httpClient.put<void>(
			`${this.environment.apiPath}/offer/reactivate`,
			reactivateOfferDto
		);
	}

	public getOfferRejectionReason(offerId: string): Observable<OfferRejectionReasonDto> {
		return this.httpClient.get<OfferRejectionReasonDto>(
			`${this.environment.apiPath}/offer/rejection/${offerId}`
		);
	}

	public getOfferCountsByStatus(timeIntervalPeriod: TimeIntervalPeriod): Observable<OfferStatusCountsDto> {
		return this.httpClient.get<OfferStatusCountsDto>(
			`${this.environment.apiPath}/offer/status/counts/${timeIntervalPeriod}`
		);
	}
}
