export enum FormFields {
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    company = 'company',
    kvk = 'kvk',
    password = 'password',
    confirmPassword = 'confirmPassword',
    municipality = 'municipality'
}
