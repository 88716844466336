export enum CreateOfferFormFields {
    title = 'title',
    description = 'description',
    amount = 'amount',
    holder = 'holder',
    validity = 'validity',
    grantsIds = 'grantsIds',
    offerTypeId = 'offerTypeId',
    timeSlots = 'timeSlots'

}
