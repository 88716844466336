import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileBrowserUtil, ModalData, commonRoutingConstants } from '@frontend/common';
import { MatDialogConfig } from '@angular/material/dialog';
import { CustomDialogComponent, CustomDialogConfigUtil } from '@frontend/common-ui';
import { DialogService } from '@windmill/ng-windmill';
import { map } from 'rxjs/operators';

@Component({
	selector: 'frontend-registration-successful',
	templateUrl: './registration-successful.component.html',
	styleUrls: ['./registration-successful.component.scss']
})
export class RegistrationSuccessfulComponent implements OnInit {
	public isCitizen: boolean;
	private readonly isMobile = MobileBrowserUtil.isMobile();

	constructor(
		private readonly dialogService: DialogService,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) { }

	public ngOnInit(): void {
		this.checkIfCitizen().subscribe(() => this.displaySuccessfulRegistrationDialog());
	}

	private navigateToLogin(): void {
		this.router.navigate([commonRoutingConstants.login]);
	}

	private checkIfCitizen() {
		return this.route.queryParams.pipe(
			map(params => {
				this.isCitizen = params['is-citizen'] === 'true';
			})
		);
	}

	private getRegistrationSuccessConfig(): MatDialogConfig {
		const modalData = this.createModalData();
		return {
			...CustomDialogConfigUtil.createMessageModal(modalData),
			disableClose: true
		};
	}

	private createModalData(): ModalData {
		const data = {
			comments: '-',
			tenantName: '',
			reason: '',
			email: ''
		};

		const dialogContent = this.getDialogContent();
		return new ModalData(
			'register.registrationSuccessful',
			'general.success.title',
			dialogContent,
			'',
			'modal.continueLogin',
			true,
			'success',
			'theme',
			'verified.svg',
			data,
			this.shouldHideActionButton()
		);
	}

	private getDialogContent(): string {
		return this.shouldHideActionButton()
			? 'general.success.accountCreatedText'
			: 'general.success.accountCreatedTextForCitizen';
	}

	private displaySuccessfulRegistrationDialog(): void {
		const config = this.getRegistrationSuccessConfig();
		this.dialogService.message(CustomDialogComponent, config)?.afterClosed()
			.subscribe(response => this.handleDialogClose(response));
	}

	private handleDialogClose(response: boolean): void {
		if (!response) return;

		if (this.isMobile) {
			MobileBrowserUtil.openMobileApp("Login");
			return;
		}
		this.navigateToLogin();
	}

	private shouldHideActionButton(): boolean {
		return !(this.isCitizen && !this.isMobile);
	}
}
