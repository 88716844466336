import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RegisterSupplier } from '../models/register-supplier.model';
import { Environment } from '@frontend/common';

@Injectable({
	providedIn: 'root'
})
export class RegisterService {
	private static httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
	constructor(@Inject('env') private environment: Environment, private httpClient: HttpClient) {}

	public registerSupplier(registerSupplier: RegisterSupplier): Observable<RegisterSupplier> {
		return this.httpClient.post<RegisterSupplier>(
			`${this.environment.apiPath}/supplier/register`,
			registerSupplier,
			{ headers: RegisterService.httpHeaders }
		);
	}
}
